/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import { Container } from 'semantic-ui-react'
 import NavBar from './NavBar'
 import SiteFooter from './SiteFooter/index'
 import styled, { createGlobalStyle } from 'styled-components'
 import bgShoppingPattern from '../images/thriftcodes-savings-bg.jpg'
 import 'semantic-ui-css/semantic.min.css'
 import AffiliateDisclosureBar from "./AffiliateDisclosureBar"
 
 
 const GlobalStyle = createGlobalStyle`
 body {
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   background-image: url(${bgShoppingPattern});
   background-repeat: repeat;
 }
 `
 
 const Placement = styled.div`
     display: flex;
     flex-direction: column;
     min-height: 100vh;
     justify-content: space-between;
     &::nth-child(1) {
       flex-grow: 1;
     }
     &::nth-child(2) {
       flex-grow: 0;
     }
 `
 
 
 const Layout = ({ children, nonAffiliatePage }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
   return (
     <Placement>
       <div>
         <title>{data.site.siteMetadata.title}</title>
         <GlobalStyle />
         {nonAffiliatePage || <AffiliateDisclosureBar />}
         <NavBar />
         <Container>
           {children}
         </Container>
         <br />
       </div><div>
         <SiteFooter />
       </div>
     </Placement>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 