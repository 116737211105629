import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container } from 'semantic-ui-react'


const MainBar = styled.div`
    text-align: center;
    width: 100vw;
    top: 0;
    color: lightgray;
    background-color: dimgray;
    padding: 5px;
`

function AffiliateDisclosureBar() {
    return (
        <MainBar>
            ThriftCodes is user-supported and may earn a commission on promoted items and coupons.
        </MainBar>
    )
}

AffiliateDisclosureBar.propTypes = {

}

export default AffiliateDisclosureBar

