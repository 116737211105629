import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MyLogo from '../MyLogo'
import { Container } from 'semantic-ui-react'
// import { Link as UnstyledLink } from 'gatsby'
import Link from 'gatsby-link'


const BarBg = styled.div`
    margin: 0;
    background-color: white;
    border-bottom: 1px solid darkgrey;
    box-shadow: 0 2px 3px grey;
    `

const MainBar = styled.div`
    display: flex;
    min-height: 65px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-bottom: 10px;
    padding-top: 10px;
    flex-direction: column;
    @media (min-width: 500px) {
        flex-direction: row;
    }
`

const LinksSection = styled.nav`
    display: flex;
    justify-content: space-around;
`

const StyledLink = styled(Link)`
    margin-right: 25px;
    padding-bottom: 3px;
    padding-top: 3px;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    color: #555;
    &:hover {
        color: darksalmon;
        border-bottom: 1px solid darksalmon;
    }
`

const staticLinks = [
    { label: 'Browse', href: '#' },
    { label: 'Submit', href: '#' },
    { label: 'Resources', href: '#' },
]

function NavBar({ navLinks = staticLinks }) {
    return (
        <BarBg>
            <Container>
                <MainBar>
                    <MyLogo />
                    <LinksSection>
                        {navLinks.map((link, i) => <StyledLink key={i} to={link.href}>{link.label}</StyledLink>)}
                    </LinksSection>
                </MainBar>
            </Container>
        </BarBg>
    )
}

NavBar.propTypes = {

}

export default NavBar

