import React from 'react'
import PropTypes from 'prop-types'
// import { Link as UnstyledLink } from 'gatsby'
import styled from 'styled-components'
import MyLogo from '../MyLogo'
import { Container } from 'semantic-ui-react'
import { color } from '../../settings'
// import { Link  } from 'gatsby' // error
import Link from 'gatsby-link' // OK

const BarBg = styled.div`
    margin: 0;
    background-color: ${color.darkest};
`

const MainBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${color.darkest};
    padding-bottom: 10px;
    padding-top: 10px;
    flex-direction: column;
    @media (min-width: 500px) {
        flex-direction: row;
    }
`

const LinksSection = styled.nav`
    color: ghostwhite;
    display: flex;
    & > * {
        padding-left: 5px;
        padding-right: 5px;
    }
`

const StyledLink = styled(Link)`
    /* margin-right: 25px; */
    padding-bottom: 3px;
    padding-top: 3px;
    font-weight: 500;
    color: ghostwhite;
    border-bottom: 1px solid transparent;
    &:hover {
        color: darksalmon;
        border-bottom: 1px solid darksalmon;
    }
`

const staticLinks = [
    { label: 'Browse', href: '#' },
    { label: 'About', href: '/about' },
]

function SiteFooter({ navLinks = staticLinks }) {
    return (
        <BarBg>
            <Container>
                <MainBar>
                    <LinksSection>
                        social
                    </LinksSection>
                    <MyLogo color="ghostwhite" />
                    <LinksSection>
                        {navLinks.map((link, i) => <StyledLink key={i} to={link.href}>{link.label}</StyledLink>)}
                    </LinksSection>
                </MainBar>
            </Container>
        </BarBg >
    )
}

SiteFooter.propTypes = {

}

export default SiteFooter
