import React from 'react'
import styled from 'styled-components'
import { color } from '../../settings'

const StyledWords = styled.div`
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    span {
        font-weight: bolder;
    }
    a {
        color:${({ fgColor }) => fgColor ? 'ghostwhite' : color.darkest};
    }
`

function MyLogo({ color }) {
    return (
        <StyledWords fgColor={color}>
            <a href="/">Thrift<span>Codes</span></a>
        </StyledWords>
    )
}

export default MyLogo
